import React from 'react'
import '../../components/styles/global.scss'
import SegmentedControl from '../../components/common/SegmentedControl'
import Layout from '../../components/common/Layout'

export default function MPN() {
    return (
        <Layout active='research-mpn-guidelines'
        title='PharmaEssentia Medical Affairs | MPN Guidelines'
        description='Guidelines to help inform the treatment of myeloproliferative neoplasms.'
        socialTitle='PharmaEssentia Medical Affairs | MPN Guidelines'
        socialDescription='Guidelines to help inform the treatment of myeloproliferative neoplasms.'
        pathname='/research/mpn-guidelines'
        >
            <section>
                <div className='inner'>
                    <h1>Areas of Research</h1>
                    <p>This section contains educational information relating to PharmaEssentia’s therapeutic areas of interest.</p>
                    <SegmentedControl buttons='areas-of-research' active='research-mpn-guidelines'/>
                    <h3>Myeloproliferative Neoplasm (MPN) Guidelines</h3>
                    <h4>NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines<sup>®</sup>)—Myeloproliferative Neoplasms</h4>
                    <a 
                    className='off-site'
                    href='https://www.nccn.org/'
                    target='_blank'
                    rel='noopener noreferrer'>Visit NCCN.org to review the guidelines <span className='triangle'></span></a>
                    <span className='divider'></span>
                    <h4>The 2016 WHO Classification of Myeloid Neoplasms and Acute Leukemia</h4>
                    <a 
                    className='off-site'
                    href='https://ashpublications.org/blood/article/127/20/2391/35255/The-2016-revision-to-the-World-Health-Organization'
                    target='_blank'
                    rel='noopener noreferrer'>View guidelines <span className='triangle'></span></a>
                    <span className='divider'></span>
                    <h4>2022 ICC MPN Subcommittee Update to the 2016/2017 WHO Classification</h4>
                    <a 
                    className='off-site'
                    href='https://pubmed.ncbi.nlm.nih.gov/36200127/'
                    target='_blank'
                    rel='noopener noreferrer'>View publication <span className='triangle'></span></a>
                </div>
            </section>
        </Layout>
    )
}